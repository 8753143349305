<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="plan-container">
          <div class="free-plan"></div>
          <div class="plan-content">
            <div class="feature-name">
              <img src="@/assets/free.svg" width="150" />
              <h2>eHomeFacts Free</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                similique ea cum alias incidunt suscipit numquam illum atque
                labore error praesentium sed ex, placeat corporis, laudantium
                doloribus ullam. Natus, reprehenderit!
              </p>
            </div>
            <div class="feature-list">
              <ul>
                <li v-for="(item, i) in free" :key="i">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="signup free">
              <v-dialog v-model="freeDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" block tile color="green" dark>
                    Free Sign Up
                  </v-btn>
                </template>
                <FreeUserSignUpForm @close="freeDialog = false" />
              </v-dialog>
            </div>
          </div>
        </div>
      </v-col>

      <v-col>
        <div class="plan-container">
          <div class="pro-plan"></div>
          <div class="plan-content">
            <div class="feature-name">
              <img src="@/assets/pro.svg" width="150" />
              <h2>eHomeFacts Pro</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Similique inventore optio veniam officiis aperiam provident
                quaerat odit ducimus quos, recusandae impedit deserunt dolorum
                laborum eligendi laudantium delectus rem et minus.
              </p>
            </div>
            <div class="feature-list">
              <ul>
                <li v-for="(item, i) in free" :key="i">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="signup pro">
              <v-dialog v-model="proDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" block tile color="blue" dark>
                    Pro Sign Up
                  </v-btn>
                </template>
                <ProUserSignUpForm @close="proDialog = false" />
              </v-dialog>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FreeUserSignUpForm from "@/components/FreeUserSignUp/FreeUserSignUpForm";
import ProUserSignUpForm from "@/components/ProUserSignUp/ProUserSignUpForm";
export default {
  components: {
    FreeUserSignUpForm,
    ProUserSignUpForm
  },
  data: () => ({
    freeDialog: false,
    proDialog: false,
    free: [
      {
        name: "unlimited home searches"
      },
      {
        name: "unlimited home searches"
      },
      {
        name: "unlimited home searches"
      },
      {
        name: "unlimited home searches"
      },
      {
        name: "unlimited home searches"
      },
      {
        name: "unlimited home searches"
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.plan-container {
  max-width: 500px;
  margin: 2rem;
  background: #fdfdfc;
  border-top: 0;
  text-align: center;

  .free-plan {
    width: 100%;
    height: 10px;
    background: green;
  }

  .pro-plan {
    width: 100%;
    height: 10px;
    background: #0091ff;
  }

  .plan-content {
    padding: 1rem;
    border: 1px solid #e0e2e5;
    border-top: 0;
  }

  .feature-name {
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .feature-list {
    ul {
      padding: 0;
      list-style-type: none;
    }

    li {
      padding: 1rem;
      &:nth-child(even) {
        background: #fafaf9;
      }
    }
  }

  .signup {
    padding: 3rem;
  }
}
</style>
